import React, { useState, useEffect } from "react";
import "./cookieBanner.css"; // Per aggiungere stile personalizzato
import { Button, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { ItemAnimation } from "../story-components/components";
const ga_id = "G-Y03LYRFEHS";
const gtag_id = "AW-16549231968";
const CONSENT_EXPIRATION_TIME = 6 * 30 * 24 * 60 * 60 * 1000;

const CookieBanner = () => {
  const [cookieConsent, setCookieConsent] = useState(false);


  useEffect(() => {
    // Se non esiste, lo genera e lo salva nel localStorage
    const consent = localStorage.getItem("cookieConsent");
    const consentDate = localStorage.getItem("cookieConsentDate");
    const googleCookieExists = checkCookieExists("_ga");

    const now = new Date().getTime();

    // Verifica se il consenso è scaduto (dopo 6 mesi)
    if (
      !consent || // Nessun consenso mai dato
      (consentDate && now - consentDate > CONSENT_EXPIRATION_TIME) || // Consenso scaduto
      (!googleCookieExists && consent === "true") // Il cookie è sparito, richiedi di nuovo se era stato accettato
    ) {
      localStorage.removeItem("cookieConsent");
      localStorage.removeItem("cookieConsentDate");
      setCookieConsent(null); // Richiede nuovamente il consenso
    } else if (consent === "true" && googleCookieExists) {
      setCookieConsent(true);
      enableGoogleTag(); // Abilita Google Analytics 4
    } else if (consent === "false") {
      setCookieConsent(false);
      disableGoogleAnalytics(); // Disabilita Google Analytics 4
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    localStorage.setItem("cookieConsentDate", new Date().getTime());
    setCookieConsent(true);
    enableGoogleTag(); // Abilita Google Tag per GA4
  };

  const handleDeclineCookies = () => {
    localStorage.setItem("cookieConsent", "false");
    localStorage.setItem("cookieConsentDate", new Date().getTime());
    setCookieConsent(false);
    disableGoogleAnalytics(); // Disabilita Google Analytics 4
  };

  const validPaths = [
    "/",
    "/Home",
    "/home",
    "/Tutorials",
    "/contact_us",
    "/subscriptions",
  ];
  if (!validPaths.includes(window.location.pathname)) {
    return null; // Non mostrare il banner su altre pagine
  }

  return (
    <>
      {cookieConsent === null && (
        <Box
          sx={{
            margin: "20px",
            borderRadius: "10px",
            padding: "10px",
            position: "fixed",
            bottom: "0",
            zIndex: "100",
          }}
        >
          <ItemAnimation variant="down" delay={4} oneTime={true} duration={2}>
            <Box className="cookie-banner">
              <Typography variant="body2" sx={{ margin: "10px" }}>
                Per offrirti un'esperienza ottimale, utilizziamo i cookie in
                conformità al GDPR. Accetta per sfruttare al meglio tutte le
                funzionalità del sito. Scopri di più nella nostra{" "}
                <Link style={{ color: "white" }} to="/privacy-policy">
                  privacy-policy
                </Link>
              </Typography>
              <Box className="cookie-banner-buttons" sx={{ padding: "5px" }}>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={handleDeclineCookies}
                  className="decline-button"
                >
                  Rifiuta
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  onClick={handleAcceptCookies}
                  className="accept-button"
                >
                  Accetta e continua
                </Button>
              </Box>
            </Box>
          </ItemAnimation>
        </Box>
      )}
    </>
  );
};

// Funzione per abilitare Google Analytics 4 (GA4)
const enableGoogleTag = () => {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${ga_id}`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", gtag_id);
    gtag("config", ga_id, { anonymize_ip: true });
  };
};

// Disabilitare Google Analytics (Consenso non accettato)
const disableGoogleAnalytics = () => {
  window[`ga-disable-${ga_id}`] = true; // Disabilita Google Analytics 4
};

// Funzione per verificare se il cookie di Google Analytics esiste
const checkCookieExists = (cookieName) => {
  return document.cookie
    .split(";")
    .some((item) => item.trim().startsWith(`${cookieName}=`));
};

export default CookieBanner;
