export function FaviconUpdater(local_id=undefined) {
  // Verifica se `local_id` è presente, altrimenti usa una favicon di default

  const faviconUrl = local_id ? `/local_logo/${local_id}` : "/Logo.png";
  let canonicalUrlTemp = `https://overlinemenu.it/local/Menu/${local_id}`;
  const icons = [
    { rel: "icon", sizes: "16x16", type: "image/png", href: `${faviconUrl}` },
    { rel: "icon", sizes: "32x32", type: "image/png", href: `${faviconUrl}` },
    { rel: "icon", sizes: "64x64", type: "image/png", href: `${faviconUrl}` },
    { rel: "apple-touch-icon", sizes: "180x180", href: `${faviconUrl}` },
  ];
  console.log("aggiorno " + local_id);
  icons.forEach(({ rel, sizes, type, href }) => {
    let link = document.querySelector(`link[rel='${rel}'][sizes='${sizes}']`);
    if (link) {
      link.href = href;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = rel;
      newLink.sizes = sizes;
      if (type) newLink.type = type;
      newLink.href = href;
      document.head.appendChild(newLink);
    }
  });
}
